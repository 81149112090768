<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo d-none d-lg-flex" v-if="isMainInstance">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Moovee
        </h2>
      </b-link>
      <b-link class="brand-logo brand-logo-full d-none d-lg-flex" v-else>
        <b-img :src="require('@/assets/images/logo/logo-v2.png')" alt="logo" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title
            class="mb-1 font-weight-bold flex-column"
            title-tag="h2"
          >
            <b-link class="mobile-logo d-flex d-lg-none" v-if="isMainInstance">
              <vuexy-logo />
              <h2 class="brand-text text-primary ml-1">
                Moovee
              </h2>
            </b-link>
            <b-link class="mobile-logo d-flex d-lg-none brand-logo-full" v-else>
              <b-img
                :src="require('@/assets/images/logo/logo-v2.png')"
                alt="logo"
              />
            </b-link>
            Приветствуем!
          </b-card-title>
          <b-card-text class="mb-2 d-none d-lg-flex">
            Авторизируйтесь чтобы создавать квесты!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form class="auth-login-form" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Пароль</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Забыли пароль?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Пароль"
                  vid="password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    size="lg"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Пароль"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                size="lg"
              >
                Войти
              </b-button>
            </b-form>
          </validation-observer>
          <div class="divider my-2" v-if="isMainInstance">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex flex-column" v-if="isMainInstance">
            <b-button @click="signInFb" variant="facebook" block size="lg">
              Войти с Facebook
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button @click="googleAuth" variant="google" block size="lg">
              Войти с Google
              <feather-icon icon="MailIcon" />
            </b-button>
          </div>

          <b-card-text class="text-center mt-2" v-if="isMainInstance">
            <span>Впервые на нашей платформе? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Создайте аккаунт</span>
            </b-link>
          </b-card-text>
          <hr v-if="isMainInstance" />
          <b-card-text class="mt-2 mt-lg-4" v-if="isMainInstance">
            <span>Регистрируясь, вы подтверждаете</span>
            <b-link href="https://moovee.io/privacy">
              <span>&nbsp;Политику конфиденциальности</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
localize("ru", ru);
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, min } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { fb, db } from "@/main";
import firebase from "firebase/compat/app";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  getAuth,
  signInWithCredential,
  FacebookAuthProvider,
} from "firebase/auth";

const auth = getAuth();

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
      min,
    };
  },
  metaInfo() {
    return {
      title: "moovee - Вход",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    isMainInstance() {
      return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV !== "white";
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          fb.auth()
            .signInWithEmailAndPassword(this.userEmail, this.password)
            .then((userCredentials) => {
              db.collection("users")
                .doc(userCredentials.user.uid)
                .get()
                .then((user) => {
                  const isAdmin = user.data().isAdmin;
                  const userData = {
                    ...userCredentials,
                    role: isAdmin ? "admin" : "creator",
                  };
                  localStorage.setItem("userData", JSON.stringify(userData));
                  localStorage.setItem("userId", userCredentials.user.uid);
                  const ability = [
                    {
                      action: "manage",
                      subject: "all",
                    },
                  ];
                  this.$ability.update(ability);

                  this.$router.push("/");
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Добро пожаловать!`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Вы успешно вошли в аккаунт, как ${userData.role}. Теперь вы можете работать!`,
                    },
                  });
                });
            })
            .catch((error) => {
              console.log(error);
              console.log(this.$refs.loginForm);
              this.$refs.loginForm.setErrors({
                email: ["Что-то пошло не так"],
              });
            });
        }
      });
    },
    googleAuth() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var user = result.user;
          db.collection("users")
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const userData = { user, role: "creator" };
                localStorage.setItem("userData", JSON.stringify(userData));
                localStorage.setItem("userId", user.uid);
                const ability = [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ];
                this.$ability.update(ability);

                this.$router.push("/");
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Добро пожаловать!`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Вы успешно вошли в аккаунт, как ${userData.role}. Теперь вы можете работать!`,
                  },
                });
              } else {
                const expiresIn = 604800;
                const expireTimestamp = firebase.firestore.Timestamp.now().toDate();
                expireTimestamp.setSeconds(
                  expireTimestamp.getSeconds() + expiresIn
                );
                db.collection("users")
                  .doc(user.uid)
                  .set({
                    email: user.email,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    tariffAt: firebase.firestore.FieldValue.serverTimestamp(),
                    tariff: "Demo",
                    tariffTill: firebase.firestore.Timestamp.fromDate(
                      expireTimestamp
                    ),
                  })
                  .then(() => {
                    const userData = { user, role: "creator" };
                    localStorage.setItem("userData", JSON.stringify(userData));
                    localStorage.setItem("userId", user.uid);
                    const ability = [
                      {
                        action: "manage",
                        subject: "all",
                      },
                    ];
                    this.$ability.update(ability);

                    this.$router.push("/");
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Добро пожаловать!`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `Вы успешно вошли в аккаунт, как ${userData.role}. Теперь вы можете работать!`,
                      },
                    });
                  });
              }
            });
        });
    },
    signInFb() {
      FB.login(
        function(response) {
          if (response.authResponse) {
            console.log(response);
            const credential = FacebookAuthProvider.credential(
              response.authResponse.accessToken
            );
            signInWithCredential(auth, credential)
              .then((result) => {
                var user = result.user;
                console.log(user);
                db.collection("users")
                  .doc(user.uid)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      const userData = { user, role: "creator" };
                      localStorage.setItem(
                        "userData",
                        JSON.stringify(userData)
                      );
                      localStorage.setItem("userId", user.uid);
                      const ability = [
                        {
                          action: "manage",
                          subject: "all",
                        },
                      ];
                      this.$ability.update(ability);

                      this.$router.push("/");
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: `Добро пожаловать!`,
                          icon: "CoffeeIcon",
                          variant: "success",
                          text: `Вы успешно вошли в аккаунт, как ${userData.role}. Теперь вы можете работать!`,
                        },
                      });
                    } else {
                      const expiresIn = 604800;
                      const expireTimestamp = firebase.firestore.Timestamp.now().toDate();
                      expireTimestamp.setSeconds(
                        expireTimestamp.getSeconds() + expiresIn
                      );
                      db.collection("users")
                        .doc(user.uid)
                        .set({
                          email: user.email,
                          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                          tariffAt: firebase.firestore.FieldValue.serverTimestamp(),
                          tariff: "Demo",
                          tariffTill: firebase.firestore.Timestamp.fromDate(
                            expireTimestamp
                          ),
                        })
                        .then(() => {
                          const userData = { user, role: "creator" };
                          localStorage.setItem(
                            "userData",
                            JSON.stringify(userData)
                          );
                          localStorage.setItem("userId", user.uid);
                          const ability = [
                            {
                              action: "manage",
                              subject: "all",
                            },
                          ];
                          this.$ability.update(ability);

                          this.$router.push("/");
                          this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                              title: `Добро пожаловать!`,
                              icon: "CoffeeIcon",
                              variant: "success",
                              text: `Вы успешно вошли в аккаунт, как ${userData.role}. Теперь вы можете работать!`,
                            },
                          });
                        });
                    }
                  });
              })
              .catch((error) => {
                if (
                  error.code === "auth/account-exists-with-different-credential"
                ) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Вы уже зарегистрированны через другой способ авторизации!`,
                      icon: "XIcon",
                      variant: "danger",
                      text: `Используйте изначальный способ авторизации!`,
                    },
                  });
                }
              });
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        }.bind(this),
        { scope: "email" }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.mobile-logo {
  margin-top: 16px;
  svg {
    height: 28px;
    width: 40.95px;
  }
}
</style>
